var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "my-3" },
    [
      _c(
        "v-card-title",
        {
          staticClass:
            "primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
        },
        [_vm._v("Debt")]
      ),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "hide-actions": "",
                      title: "Revolving Accounts",
                      stat: "$5,000 Balance Due",
                      "color-option": 1,
                      icon: "mdi-credit-card-refresh"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "hide-actions": "",
                      title: "Revolving Accounts",
                      stat: "$1,000 Past Due",
                      "color-option": 2,
                      icon: "mdi-credit-card-refresh"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c("psi-stats-card", {
                    attrs: {
                      "hide-actions": "",
                      title: "Collection Accounts",
                      stat: "$4,000 Balance",
                      "color-option": 3,
                      icon: "mdi-file-phone"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticClass: "mr-3",
                        attrs: { size: "100", color: "primary" }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "80", color: "white" } },
                          [_vm._v("mdi-transmission-tower")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text-center" }, [
                      _c("h1", { staticClass: "mb-2" }, [_vm._v("$2,000")]),
                      _c(
                        "h3",
                        { staticClass: "font-weight-regular text-uppercase" },
                        [_vm._v(" Utility Debt ")]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticClass: "mr-3",
                        attrs: { size: "100", color: "secondary" }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "80", color: "white" } },
                          [_vm._v("mdi-home-city")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text-center" }, [
                      _c("h1", { staticClass: "mb-2" }, [_vm._v("$4,000")]),
                      _c(
                        "h3",
                        { staticClass: "font-weight-regular text-uppercase" },
                        [_vm._v(" Property Debt ")]
                      )
                    ])
                  ],
                  1
                )
              ]),
              _c("v-col", [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c(
                      "v-avatar",
                      {
                        staticClass: "mr-3",
                        attrs: { size: "100", color: "accent" }
                      },
                      [
                        _c(
                          "v-icon",
                          { attrs: { size: "80", color: "white" } },
                          [_vm._v("mdi-school")]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "text-center" }, [
                      _c("h1", { staticClass: "mb-2" }, [_vm._v("$10,000")]),
                      _c(
                        "h3",
                        { staticClass: "font-weight-regular text-uppercase" },
                        [_vm._v(" Student Loan Debt ")]
                      )
                    ])
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }