<template>
    <v-card class="my-3">
        <v-card-title
            class="primary text-uppercase white--text pa-2 mb-2 text-subtitle-2"
            >Debt</v-card-title
        >

        <v-card-text>
            <v-row>
                <v-col>
                    <psi-stats-card
                        hide-actions
                        title="Revolving Accounts"
                        stat="$5,000 Balance Due"
                        :color-option="1"
                        icon="mdi-credit-card-refresh"
                    ></psi-stats-card>
                </v-col>
                <v-col>
                    <psi-stats-card
                        hide-actions
                        title="Revolving Accounts"
                        stat="$1,000 Past Due"
                        :color-option="2"
                        icon="mdi-credit-card-refresh"
                    ></psi-stats-card>
                </v-col>
                <v-col>
                    <psi-stats-card
                        hide-actions
                        title="Collection Accounts"
                        stat="$4,000 Balance"
                        :color-option="3"
                        icon="mdi-file-phone"
                    ></psi-stats-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <div class="d-flex align-center">
                        <v-avatar size="100" color="primary" class="mr-3">
                            <v-icon size="80" color="white"
                                >mdi-transmission-tower</v-icon
                            >
                        </v-avatar>
                        <div class="text-center">
                            <h1 class="mb-2">$2,000</h1>
                            <h3 class="font-weight-regular text-uppercase">
                                Utility Debt
                            </h3>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <div class="d-flex align-center">
                        <v-avatar size="100" color="secondary" class="mr-3">
                            <v-icon size="80" color="white"
                                >mdi-home-city</v-icon
                            >
                        </v-avatar>
                        <div class="text-center">
                            <h1 class="mb-2">$4,000</h1>
                            <h3 class="font-weight-regular text-uppercase">
                                Property Debt
                            </h3>
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <div class="d-flex align-center">
                        <v-avatar size="100" color="accent" class="mr-3">
                            <v-icon size="80" color="white">mdi-school</v-icon>
                        </v-avatar>
                        <div class="text-center">
                            <h1 class="mb-2">$10,000</h1>
                            <h3 class="font-weight-regular text-uppercase">
                                Student Loan Debt
                            </h3>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "applicant-debt",
    components: {},
    props: {},
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>